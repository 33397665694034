<template>
  <div class="card">
    <div class="mt-5 lg:mt-0 flex flex-col justify-center bg-white rounded-lg lg:py-7 lg:px-14 shadow-md cardFixed" v-if="!loading">
      <div class="hidden lg:block">
        <CardStoreInfo :average_star="average_star"></CardStoreInfo>
      </div>
      <div class="lg:hidden" v-if="!editDetail">
        <div class="rounded-md bg-white w-full text-sm md:text-lg font-medium text-grayColor my-2">
          <div class="flex justify-center items-center">
            <div class="flex py-3 px-2 h-11 md:h-16 items-center">
              <img class="mr-2 md:w-6" src="@/assets/image/svg_adult.svg" alt="adultIcon" />
              <span>{{adultNum}}</span>
            </div>
            <div class="flex py-3 px-2 h-11 md:h-16 items-center">
              <img class="mr-2 md:w-6" src="@/assets/image/svg_child.svg" alt="childIcon" />
              <span>{{childNum}}</span>
            </div>
            <div class="flex py-3 px-2 h-11 md:h-16  items-center">
              <img class="mr-2 md:w-6" src="@/assets/image/svg_chair.svg" alt="chairIcon" />
              <span>{{childseat}}</span>
            </div>
          </div>
          <div class="flex justify-center items-center">
            <div class="flex py-2 px-2 items-center">
              <img class="mr-2 md:w-6 md:h-16 " src="@/assets/image/svg_calendar.svg" alt="calendarIcon" />
              <span>{{clickYear}}/{{clickMonth}}/{{clickDay}}</span>
            </div>
            <div class="flex px-2 items-center">
              <img class="mr-2 md:w-6 md:h-16 " src="@/assets/image/svg_time.svg" alt="timcIcon" />
              <span>{{orderTime}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class=" w-full" v-if="editDetail || editWeb ">
        <div class="flex justify-center md:flex-col mt-5 text-gray-500">
          <div class="flex flex-col md:flex-row lg:justify-between md:justify-center md:items-center mx-2 md:mx-0 ">
            <div class="flex md:block lg:w-1/4 md:w-20 mb-2 md:mb-0">
              <span class="text-xs md:text-base font-semibold mr-1 md:mr-0">成人</span>
              <img class="w-3 md:hidden" src="@/assets/image/svg_adult_gray.svg" alt="adultNumber" />
            </div>
            <div class="flex flex-row border md:h-10 rounded-md border-gray-300 relative shadow w-20 md:w-32 ">
              <button class="hover:bg-gray-200 border-gray-400 h-full w-7 md:w-28 flex rounded-l focus:outline-none cursor-pointer items-center" @click="adultNumber(-1)" :disabled="adultNum === 1">
                <span class="m-auto text-2xl font-extralight">-</span>
              </button>
              <div class="bg-white w-3 md:w-24 text-xs flex items-center justify-center cursor-default px-3 md:px-6">
                <input type="hidden" class="border-gray-400 focus:outline-none text-center" value="adultNum" />
                <span class="text-sm font-semibold text-black">{{ adultNum }}</span>
              </div>
              <button class="hover:bg-gray-200 border-gray-400 h-full w-7 md:w-28 flex rounded-r focus:outline-none cursor-pointer" :class="{isFull :isFull}" @click="adultNumber(+1)" :disabled="adultNum === store.order_settings.single_time_order_total || (adultNum + childNum) >= store.order_settings.single_time_order_total">
                <span class="m-auto text-2xl font-extralight">+</span>
              </button>
            </div>
            <span class="font-semibold text-right lg:w-1/4 md:w-12 hidden md:block">位</span>
          </div>

          <div class="flex flex-col md:flex-row lg:justify-between md:justify-center md:items-center mx-2 md:mx-0 md:mt-5">
            <div class="flex md:block lg:w-1/4 md:w-20 mb-2 md:mb-0">
              <span class="text-xs md:text-base font-semibold mr-1 md:mr-0">小孩</span>
              <img class="w-3 md:hidden" src="@/assets/image/svg_child_gray.svg" alt="adultNumber" />
            </div>
            <div class="flex flex-row border md:h-10 rounded-md border-gray-300 relative shadow w-20 md:w-32 ">
              <button class="hover:bg-gray-200 border-gray-400 h-full w-7 md:w-28 flex rounded-l focus:outline-none cursor-pointer items-center" @click="childNumber(-1)" :disabled="childNum === 0">
                <span class="m-auto text-2xl font-extralight">-</span>
              </button>
              <div class="bg-white w-3 md:w-24 text-xs flex items-center justify-center cursor-default px-3 md:px-6">
                <input type="hidden" class="border-gray-400 focus:outline-none text-center" :value="childNum" />
                <span class="text-sm font-semibold text-black">{{ childNum }}</span>
              </div>
              <button class="hover:bg-gray-200 border-gray-400 h-full w-7 md:w-28 flex rounded-r focus:outline-none cursor-pointer" :class="{isFull :isFull}" @click="childNumber(+1)" :disabled="adultNum === store.order_settings.single_time_order_total || (adultNum + childNum) >= store.order_settings.single_time_order_total">
                <span class="m-auto text-2xl font-extralight">+</span>
              </button>
            </div>
            <span class="font-semibold lg:w-1/4 md:w-12  text-right hidden md:block">位</span>
          </div>

          <div v-if="childNum >= 1">
            <div class="flex flex-col md:flex-row lg:justify-between md:justify-center md:items-center md:mt-5">
              <div class="flex md:block lg:w-1/4 md:w-20  mb-2 md:mb-0">
                <span class="text-xs md:text-base font-semibold mr-1 md:mr-0">兒童座椅</span>
                <img class="w-3 md:hidden" src="@/assets/image/svg_chair_gray.svg" alt="adultNumber" />
              </div>
              <div class="flex flex-row border md:h-10 rounded-md border-gray-300 relative shadow w-20 md:w-32 ">
                <button class="hover:bg-gray-200 border-gray-400 h-full w-7 md:w-28 flex rounded-l focus:outline-none cursor-pointer items-center" @click="childseatQtyMinus(-1)">
                  <span class="m-auto text-2xl font-extralight">-</span>
                </button>
                <div class="bg-white w-3 md:w-24 text-xs flex items-center justify-center cursor-default px-3 md:px-6">
                  <input type="hidden" class="border-gray-400 focus:outline-none text-center" :value="childseat" />
                  <span class="text-sm font-semibold text-black">{{
                    childseat
                  }}</span>
                </div>
                <button class="hover:bg-gray-200 border-gray-400 h-full w-7 md:w-28 flex rounded-r focus:outline-none cursor-pointer" :class="{isFull :isSeatFull}" @click="childseatQtyPlus(1)">
                  <span class="m-auto text-2xl font-extralight">+</span>
                </button>
              </div>
              <span class="font-semibold lg:w-1/4 md:w-12  text-right hidden md:block">張</span>
            </div>
          </div>
        </div>
        <p class="p-3 md:hidden text-xs text-grayColor-light text-center">* 超過 {{store.order_settings.single_time_order_total}} 人或有訂位以外的需求，請使用電話預約！ </p>

        <div class="bg-gray-100 p-5 my-5 hidden md:block">
          <p class="text-sm text-center px-2 text-gray-500 font-bold">
            可接受 1-{{store.order_settings.single_time_order_total}} 位訂位（含大人與小孩） <br />
            * 超過 {{store.order_settings.single_time_order_total}} 人 或 有訂位以外的需求，<br />請使用電話預約！
          </p>
        </div>
        <Calendar :store="store" :canOrderTimes="canOrderTimes" :loading="loading" @update="updateDate"></Calendar>

        <div class="wrap">
          <div class="cont">
            <div class="md:my-4 md:mx-5 lg:mx-0 md:flex md:flex-wrap  md:justify-start text-center state orderTimeWrap ">
              <button class="timeOption hover:bg-yellow-100" v-for="i in businessHour" :key="i" :class="{unavaliableTime: getArrDifference.find((time) => time === i), 
              timeoptionActive: orderTime === i }" @click="onClicktime(i)"> {{i}} </button>
            </div>
          </div>
        </div>

        <p class="p-3 md:pb-5 text-grayColor-light text-xs md:text-sm text-center">
          *灰色表已滿，可點選檢視同時段其他可訂位日期
        </p>

        <div class="text-white text-sm md:text-lg flex justify-center border-gray-100 lg:border-t-2 pt-5 mb-5">
          <button class="btn btn-gray mr-3 " @click="clearAll">清空</button>
          <button class="btn btn-main " :class="{disabledBtn : !isGoNext}" @click="editDone">儲存修改</button>
        </div>
      </div>

      <div class="my-5 hidden lg:block" v-if="!editWeb">
        <div class="flex justify-between">
          <p class=" text-grayColor text-lg font-semibold">訂位人數及時間 /</p>
          <div class="flex justify-center items-center">
            <img class="pr-2" src="@/assets/image/icon_edit.svg" alt="" />
            <p class=" text-grayColor font-semibold  underline cursor-pointer text-lg" @click="edit">修改</p>
          </div>
        </div>
        <div class="flex flex-col items-center bg-fill-light  p-8 my-5 rounded-md text-lg text-grayColor font-semibold ">
          <div class="flex w-full text-center">
            <span class="p-2 w-1/3">成人</span>
            <span class="p-2 w-1/3">{{adultNum}}</span>
            <span class="p-2 w-1/3">位</span>
          </div>
          <div class="flex w-full text-center">
            <span class="p-2 w-1/3">小孩</span>
            <span class="p-2 w-1/3">{{childNum}}</span>
            <span class="p-2 w-1/3">位</span>
          </div>
          <div class="flex w-full text-center">
            <span class="p-2 w-1/3">兒童座椅</span>
            <span class="p-2 w-1/3">{{childseat}}</span>
            <span class="p-2 w-1/3">張</span>
          </div>
          <div class="flex items-center w-full text-center">
            <span class="p-2 w-1/3 flex justify-center"><img class="pr-2" src="@/assets/image/icon_Union.png" alt="" /></span>
            <span class="p-2 w-1/3">{{clickMonth}}月{{clickDay}}日</span>
            <span class="p-2 w-1/3">(週{{chineseDay}})</span>
          </div>
          <div class="flex w-full text-center items-center">
            <span class="p-2 w-1/3 flex justify-center"><img class="pr-2" src="@/assets/image/icon_clock.png" alt="" /></span>
            <span class="p-2 w-2/3">{{orderTime}}</span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { toMatrix } from "@/assets/filters/util.js";
import emitter from "@/assets/filters/emitter.js";
import CardStoreInfo from "./CardStoreInfo.vue";
import Calendar from "./Calendar.vue";
import { mapFields } from "vuex-map-fields";
// import moment from "moment";
import {
  cloneDate,
  getCurrentMonthLastDay,
  getPrevMonthLastDay,
  getYearMonthDay,
} from "@/assets/filters/date.js";

const MONTHS = [
  "1月",
  "2月",
  "3月",
  "4月",
  "5月",
  "6月",
  "7月",
  "8月",
  "9月",
  "10月",
  "11月",
  "12月",
];
const lengths = 5;
const starOn = "on";
const starHalf = "half";
const starOff = "off";
export default {
  name: "CardConfirm",
  components: {
    CardStoreInfo,
    Calendar,
  },
  props: {
    storeID: {
      type: Number,
    },
    editDetail: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [emitter],
  data() {
    return {
      editWeb: false,
      isloading: false,
      boxshow: false,
      showtime: false,
      chineseDay: "",
      months: toMatrix(MONTHS, 4),
      weeks: ["日", "一", "二", "三", "四", "五", "六"],
      monthPanel_show: false,
      businessHour: [],
      avaliableHour: [],
      avaliableDate: [],
      // dayPanel: false,
      canOrderTimes: [],
      // isGoNext: Boolean,
    };
  },
  methods: {
    getBusinessTime() {
      const api = `${process.env.VUE_APP_API}/store/${this.storeID}/can-order-times/${this.apiDate}/${this.count}`;
      const vm = this;

      vm.isloading = true;
      if (this.apiDate !== "NaN-NaN-NaN") {
        vm.$http.get(api).then((response) => {
          vm.businessHour = response.data.return.business_times;
          vm.avaliableHour = response.data.return.can_order_times;
          vm.isloading = false;
        });
      }
    },
    updateDate(val) {
      // this.tempValue = cloneDate(val);
      // this.$store.commit("TEMPVALUE", cloneDate(val));
      this.getBusinessTime();
    },
    getcanOrderDate() {
      const api = `${process.env.VUE_APP_API}/store/${this.storeID}/can-order-date/${this.orderTime}/${this.count}`;
      const vm = this;
      vm.$http.get(api).then((response) => {
        this.canOrderTimes = response.data.return.dates;
        // this.canOrderTimes = response.data.return;
      });
    },
    edit() {
      const vm = this;
      vm.editWeb = true;
      localStorage.setItem("adultNum", vm.adultNum);
      localStorage.setItem("childNum", vm.childNum);
      localStorage.setItem("childseat", vm.childseat);
      localStorage.setItem("tempValue", vm.tempValue);
      localStorage.setItem("orderTime", vm.orderTime);

      let year = JSON.parse(localStorage.getItem("clickYear"));
      let month = JSON.parse(localStorage.getItem("clickMonth"));
      let day = JSON.parse(localStorage.getItem("clickDay"));
      let days = new Date(year, month - 1, day);
      this.$store.commit("ISGONEXT", true);
      this.$store.commit("TEMPVALUE", days);
      this.getBusinessTime();
    },
    editDone() {
      const vm = this;
      this.$store.commit(
        "CLICKDAY",
        JSON.parse(localStorage.getItem("clickDay"))
      );
      this.$store.commit(
        "CLICKMONTH",
        JSON.parse(localStorage.getItem("clickMonth"))
      );
      this.$store.commit(
        "CLICKYEAR",
        JSON.parse(localStorage.getItem("clickYear"))
      );
      this.$store.commit(
        "CLICKWEEK",
        JSON.parse(localStorage.getItem("clickWeek"))
      );
      // this.$store.commit("ORDERTIME", localStorage.getItem("orderTime"));
      localStorage.setItem("adultNum", vm.adultNum);
      localStorage.setItem("childNum", vm.childNum);
      localStorage.setItem("childseat", vm.childseat);
      localStorage.setItem("tempValue", vm.tempValue);
      // localStorage.setItem("orderTime", vm.orderTime);
      // this.getReservation();
      // this.$emit("update:editDetail");

      if (this.orderTime == null || undefined || "") {
        alert("請選擇用餐時間");
      } else {
        if (!this.isGoNext) {
          alert("請選擇可用餐時段");
        } else if (this.isGoNext) {
          this.$emit("update:editDetail");
          this.getReservation();
          vm.editWeb = false;
        }
      }
    },
    changeNumber() {
      this.$store.dispatch("changeNumber");
    },
    adultNumber(num) {
      this.$store.dispatch("adultNumber", num);
    },
    childNumber(num) {
      this.$store.dispatch("childNumber", num);
    },
    childseatQtyPlus() {
      this.$store.dispatch("childseatQtyPlus");
    },
    childseatQtyMinus() {
      this.$store.dispatch("childseatQtyMinus");
    },
    togglebox() {
      this.boxshow = !this.boxshow;
    },
    toggleshowtime() {
      this.showtime = !this.showtime;
    },
    changeYear(value) {
      const [year] = getYearMonthDay(this.tempValue);
      this.tempValue = new Date(
        cloneDate(this.tempValue).setFullYear(year + value)
      );
    },
    // onClickMonth(i, j) {
    //   const month = j + i * 4;
    //   const { year, day } = this.formatDate;
    //   this.tempValue = new Date(year, month, day);
    //   this.monthPanel_show = false;
    // },
    onClicktime(i) {
      // this.$store.commit("ORDERTIME", i);
      this.$store.commit("CLICKORDERTIME", i);
      // this.dayPanel = true;
      localStorage.setItem("orderTime", JSON.stringify(this.orderTime));

      if (this.avaliableHour.includes(this.orderTime)) {
        this.$store.commit("ISGONEXT", true);
      } else {
        this.$store.commit("ISGONEXT", false);
      }
      this.anotherDate();
      this.getcanOrderDate();
    },
    anotherDate(data) {
      let d = new Date(data),
        m1 = d.getMonth(data) + 1,
        d1 = d.getDate(data),
        y1 = d.getFullYear(data);
      if (m1 < 10) {
        m1 = "0" + m1;
      }
      if (d1 < 10) {
        d1 = "0" + d1;
      }
      const OptionDate = y1 + "-" + m1 + "-" + d1;
      const result = this.canOrderTimes.find((word) => word === OptionDate);
      return result;
    },
    getDay(cell) {
      return cell.date.getDate();
    },
    clearAll() {
      this.$store.commit("ADULTNUM", 1);
      this.$store.commit("CHILDNUM", 0);
      this.$store.commit("CHILDSEATNUM", 0);
      this.$store.commit("ORDERTIME", "");
      this.$store.commit("TEMPVALUE", cloneDate(this.value));
    },
    getReservation() {
      if (JSON.parse(localStorage.getItem("adultNum")) === null) {
        this.$store.commit("ADULTNUM", 1);
      } else {
        const num = JSON.parse(localStorage.getItem("adultNum"));
        this.$store.commit("ADULTNUM", num);
      }

      if (JSON.parse(localStorage.getItem("childNum")) === null) {
        this.$store.commit("CHILDNUM", 0);
      } else {
        const childNum = JSON.parse(localStorage.getItem("childNum"));
        this.$store.commit("CHILDNUM", childNum);
      }

      if (JSON.parse(localStorage.getItem("childseat")) === null) {
        this.$store.commit("CHILDSEATNUM", 0);
      } else {
        const childseat = JSON.parse(localStorage.getItem("childseat"));
        this.$store.commit("CHILDSEATNUM", childseat);
      }
      this.$store.commit("CLICKORDERTIME", localStorage.getItem("orderTime"));
      this.$store.commit(
        "CLICKDAY",
        JSON.parse(localStorage.getItem("clickDay"))
      );
      this.$store.commit(
        "CLICKMONTH",
        JSON.parse(localStorage.getItem("clickMonth"))
      );
      this.$store.commit(
        "CLICKYEAR",
        JSON.parse(localStorage.getItem("clickYear"))
      );
      this.$store.commit(
        "CLICKWEEK",
        JSON.parse(localStorage.getItem("clickWeek"))
      );

      if (this.clickWeek === 1) {
        this.chineseDay = "一";
      } else if (this.clickWeek === 2) {
        this.chineseDay = "二";
      } else if (this.clickWeek === 3) {
        this.chineseDay = "三";
      } else if (this.clickWeek === 4) {
        this.chineseDay = "四";
      } else if (this.clickWeek === 5) {
        this.chineseDay = "五";
      } else if (this.clickWeek === 6) {
        this.chineseDay = "六";
      } else if (this.clickWeek === 0) {
        this.chineseDay = "日";
      }
    },
  },
  computed: {
    isGoNext() {
      return this.$store.state.isGoNext;
    },
    isFull() {
      if (this.count === this.store.order_settings.single_time_order_total) {
        return true;
      } else {
        return false;
      }
    },
    isSeatFull() {
      const maxSeat = this.childNum;
      if (this.childseat === maxSeat) {
        return true;
      } else {
        return false;
      }
    },
    adultNum() {
      return this.$store.state.adultNum;
    },
    childNum() {
      return this.$store.state.childNum;
    },
    childseat() {
      return this.$store.state.childseat;
    },
    clickYear() {
      return this.$store.state.clickYear;
    },
    clickMonth() {
      return this.$store.state.clickMonth;
    },
    clickDay() {
      return this.$store.state.clickDay;
    },
    clickWeek() {
      return this.$store.state.clickWeek;
    },
    orderTime() {
      return this.$store.state.orderTime;
    },
    value() {
      return this.$store.state.value;
    },
    store() {
      return this.$store.state.store;
    },
    loading() {
      return this.$store.state.loading;
    },
    tempValue() {
      return this.$store.state.tempValue;
    },
    count() {
      return this.adultNum + this.childNum;
    },
    defaultTime() {
      const array1 = this.avaliableHour;
      const defaultArray = [];
      const today = new Date().toLocaleDateString();
      const found = array1.find((element) => {
        const ele = new Date(today + " " + element);
        const now = new Date();
        return ele > now;
      });
      defaultArray.push(found);
      // this.orderTime = defaultArray[0];
      this.$store.commit("ORDERTIME", defaultArray[0]);

      return defaultArray;
    },
    getArrDifference() {
      return this.businessHour
        .concat(this.avaliableHour)
        .filter(function (v, i, arr) {
          const unavaliable = arr.indexOf(v) === arr.lastIndexOf(v);
          return unavaliable;
        });
    },
    getday() {
      let today = new Date();
      return today.getDay();
    },
    formatDate() {
      const [year, month, day] = getYearMonthDay(this.tempValue);
      localStorage.setItem("clickMonth", month + 1);
      localStorage.setItem("clickDay", day);
      localStorage.setItem("clickYear", year);
      return { year, month: month + 1, day };
    },
    apiDate() {
      let d = new Date(this.tempValue),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return [year, month, day].join("-");
    },
    getDays() {
      const [year, month] = getYearMonthDay(this.tempValue);
      let startWeek = new Date(year, month, 1).getDay();
      if (startWeek === 0) {
        startWeek = 7;
      }
      const prevLastDay = getPrevMonthLastDay(year, month);
      const curLastDay = getCurrentMonthLastDay(year, month);
      const days = [
        ...this.getPrevMonthDays(prevLastDay, startWeek),
        ...this.getCurrentMonthDays(curLastDay),
        ...this.getNextMonthDays(curLastDay, startWeek),
      ];
      return toMatrix(days, 7);
    },
    average_star() {
      let result = [];
      let score = Math.floor(this.store.comments.avg_score * 2) / 2;
      let starhalf = score % 1 != 0 ? true : false;
      let fullstar = Math.floor(score);

      for (let i = 0; i < fullstar; i++) {
        result.push(starOn);
      }
      if (starhalf) {
        result.push(starHalf);
      }
      if (result.length < lengths) {
        let offstar = lengths - result.length;
        for (let i = 0; i < offstar; i++) {
          result.push(starOff);
        }
      }
      return result;
    },
  },
  watch: {
    count() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/store/${this.storeID}/can-order-times/${this.apiDate}/${this.count}`;
      vm.$http
        .get(api)
        .then((response) => {
          vm.businessHour = response.data.return.business_times;
          vm.avaliableHour = response.data.return.can_order_times;
        })
        .catch((error) => {
          if (error.response.data) {
            // this.loading = false;
          }
        });
    },
  },
  mounted() {
    document.body.addEventListener("click", this.onClickBody);
  },
  beforeUnmount() {
    document.body.removeEventListener("click", this.onClickBody);
  },
  created() {
    if (this.tempValue === "") {
      this.$store.commit(
        "TEMPVALUE",
        new Date(this.clickYear, this.clickMonth - 1, this.clickDay)
      );
      // this.tempValue = new Date(this.clickYear, this.clickMonth - 1, this.clickDay)
    }
    this.getReservation();
    if (this.$store.state.tempValue !== "") {
      this.getBusinessTime();
    }
  },
};
</script>

<style scoped>
.orderTimeWrap {
  display: flex;
}

@media (min-width: 1024px) {
  .orderTimeWrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-gap: 20px; */
    /* height: 100px; */
  }
}
.disabledBtn {
  pointer-events: none;
  opacity: 0.5;
}
.isFull {
  cursor: default;
  opacity: 0.7;
  background: rgba(236, 236, 236, 0.582);
  pointer-events: none;
}
</style>