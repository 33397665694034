<template>
  <CarouselBanner :store-info="store" :loading="loading" />
  <div v-if="!loading">
    <div class="flex justify-center bg-fill-light" v-if="!loading">
      <div class="vld-parent">
        <loading :active="isLoading" :can-cancel="false" :is-full-page="true" :lock-scroll="true" />
      </div>
      <main class="container py-5 md:py-10 px-10" v-if="!loading">
        <!-- <div class="flex px-10 pt-5 md:pt-11 justify-center lg:justify-between"> -->
        <div class="lg:flex lg:flex-row gap-10 justify-center">
          <div class="left ">
            <h1 class="h1Font" v-if="!loading">
              {{ store.store.name }}
            </h1>
            <div v-if="store.store.tags.length > 0" class="info-event-tag">
              <img src="@/assets/image/tag-03.svg" alt="tag icon" />
              {{store.store.tags[0]}}
            </div>
            <div class="lg:hidden mt-2 md:mt-5" v-if="!loading">
              <div class="flex items-center pb-5 tracking-wider">
                <h5 class="pr-3 font-bold text-xs md:text-2xl">
                  {{ store.comments.avg_score }}
                </h5>
                <span v-for="(item, index) in average_star" class="star-item mr-2" :key="index" :class="item"></span>
                <span class="text-grayColor text-xs md:text-2xl">(</span>
                <span class="text-grayColor font-bold text-xs md:text-2xl">
                  {{ store.comments.count.total }}</span>
                <span class="text-grayColor text-xs md:text-2xl">)</span>
              </div>
              <CardStoreInfo></CardStoreInfo>
            </div>
            <div class="my-4 flex justify-between lg:hidden" v-if="!editDetail">
              <p class=" text-grayColor text-xs md:text-lg font-semibold">訂位人數及時間 /</p>
              <div class="flex justify-center items-center">
                <img class="pr-2 w-5 md:w-7 " src="@/assets/image/icon_edit.svg" alt="edit" />
                <p class="text-grayColor font-semibold underline cursor-pointer text-xs md:text-lg" @click="edit">修改</p>
              </div>
            </div>

            <CardConfirm :storeID="store.store.id" class="lg:hidden mx-auto" :editDetail="editDetail" @update:editDetail="changeEditDetail"></CardConfirm>
            <div class="my-4 md:my-8 border border-main rounded-md text-center py-2 text-main text-sm md:text-xl">
              填寫聯絡資料
            </div>
            <!-- contact info -->
            <Form v-slot="{ errors,meta }" :validation-schema="schema" @submit="onSubmit">
              <div class="grid grid-cols-1 mb-8">
                <label id="orderName" class="text-grayColor-darkest font-semibold text-xs md:text-base "><span class="text-main">*</span>訂位人姓名</label>
                <div class="flex justify-between items-center ">
                  <div class="md:w-3/12 mr-2">
                    <Field name="title" as="select" class="inputStyle text-xs md:w-full md:text-base rounded-lg border mt-1 focus:outline-none focus:ring-2 focus:ring-main focus:border-transparent" :class="{ 'border-status-red': errors.title }" v-model="userOrder.gender" onkeydown="if(event.keyCode==13){return false;}">
                      <option value="0">小姐</option>
                      <option value="1">先生</option>

                    </Field>
                  </div>
                  <div class="w-full">
                    <Field name="name" type="text" v-model="userOrder.name" class="text-xs md:text-base w-full py-2 px-2 md:px-3 rounded-md mt-1 focus:outline-none focus:ring-2 focus:ring-main focus:border-transparent border" placeholder="全名" :class="{ 'border-status-red': errors.name }" onkeydown="if(event.keyCode==13){return false;}" />
                  </div>
                </div>
                <div class="pt-2 text-xs md:text-sm text-grayColor">
                  <div class="invalid-feedback text-red-500">{{errors.name}}</div>
                  <p>請提供到場時方便核對的姓名。</p>

                </div>
              </div>
              <div class="grid grid-cols-1 mb-8">
                <label class="text-grayColor-darkest font-semibold text-xs md:text-base"><span class="text-main">*</span>訂位人手機號碼</label>
                <div class="flex justify-between items-center ">
                  <div class="mr-2">
                    <select class="inputStyle text-xs md:text-base rounded-lg border mt-1  focus:outline-none focus:ring-2 focus:ring-main focus:border-transparent " v-model="userOrder.phone_prefix">
                      <option value="886">+886</option>
                      <!-- <option :value="code.phoneCode" v-for="code in postCode" :key="code.phoneCode">+ {{code.phoneCode}}</option> -->
                    </select>
                  </div>
                  <div class="w-full py-2 rounded-md mt-1 focus:outline-none focus:ring-2 focus:ring-main">
                    <Field name="phone" type="number" inputmode="numeric" class="text-xs md:text-base w-full py-2 px-2 md:px-3 rounded-md mt-1 focus:outline-none focus:ring-2 focus:ring-main focus:border-transparent border" placeholder="0909123456" :class="{ 'border-status-red': errors.phone }" v-model="userOrder.phone" onkeydown="if(event.keyCode==13){return false;}" />
                  </div>
                </div>
                <div class="pt-2 text-xs md:text-sm text-grayColor">
                  <div class="invalid-feedback text-red-500">{{errors.phone}}</div>
                  <p>訂位完成後，我們將發送訂位確認簡訊通知到您的手機。</p>
                </div>
              </div>
              <div class="grid grid-cols-1 mb-8">
                <label class="text-grayColor-darkest font-semibold text-xs md:text-base"><span class="text-main">*</span>訂位人e-mail</label>
                <div class="w-full py-2 rounded-md mt-1 focus:outline-none focus:ring-2 focus:ring-main">
                  <Field name="email" type="text" class="text-xs md:text-base w-full py-2 px-2 md:px-3 rounded-md mt-1 focus:outline-none focus:ring-2 focus:ring-main focus:border-transparent border" placeholder="123@email.com" :class="{ 'border-status-red': errors.email }" v-model="userOrder.email" onkeydown="if(event.keyCode==13){return false;}" />
                </div>
                <div class="pt-2 text-xs md:text-sm text-grayColor">
                  <div class="text-red-500">{{errors.email}}</div>
                  <p>
                    訂位完成後，我們將發送訂位確認信到您的電子信箱。
                  </p>

                </div>
              </div>
              <div class="grid grid-cols-1 mb-8">
                <p class="text-grayColor-darkest font-semibold text-xs md:text-base mb-2"><span class="text-main">*</span>
                  用餐目的
                </p>
                <div class="pb-3 text-xs md:text-base text-grayColor purpose" v-if="!loading">
                  <span class="mb-2 cursor-pointer border border-gray-400 bg-white focus:outline-none purposeBtn text-center" :class="{goalActive : userOrder.goal_id === goal.id} " v-for="goal in store.goals" :key="goal.id" @click="userOrder.goal_id = goal.id">
                    {{goal.name}}
                  </span>
                </div>
              </div>
              <div class="grid grid-cols-1">
                <p class="text-grayColor-darkest font-semibold text-xs md:text-base mb-2">
                  其他備註
                </p>
                <textarea name="comment" id="" cols="30" rows="10" placeholder="請輸入內容" class="text-xs md:text-base w-full py-2 px-2 md:px-3 rounded-md focus:outline-none focus:ring-2 focus:ring-main" v-model="userOrder.comment" onkeydown="if(event.keyCode==13){return true;}"></textarea>
                <p class="flex items-center pt-2 text-xs md:text-sm text-grayColor">
                  例如行動不便/特殊需求/對花生過敏/有嬰兒等等。
                </p>
              </div>
              <div class="py-5 ">
                <div class="flex flex-col md:flex-row justify-between">
                  <div class="checkContainer flex flex-col">
                    <div class="flex items-center">
                      <span class="text-main text-sm font-bold">*</span>
                      <label for="acceptTerms">
                        <input name="acceptTerms" v-model="checked" type="checkbox" id="acceptTerms" :value="true" :unchecked-value="false" class="checkbox" />
                        <span class="checkmark rounded-sm cursor-pointer"></span>
                        <p class="text-xs md:text-sm text-grayColor-darkest pl-7 cursor-pointer">
                          我已閱讀並同意
                          <a href="/privacy-policy" target="_blank" class="text-main underline">JoyMap</a>
                          隱私條款
                        </p>
                      </label>
                    </div>
                    <div class="text-red-500 text-sm" v-if="!checked && checked !== '' ">請同意並閱讀隱私條款</div>

                  </div>

                  <div class="text-lg mt-5 md:mt-0 flex justify-center">
                    <a class="btn btn-gray md:px-5 md:py-2 mr-2 text-center justify-center flex items-center cursor-pointer" @click="clearAll">
                      重新填寫
                    </a>
                    <!-- <button class="btn btn-main md:px-5 md:py-2" type="submit" :class="{disabled : !(meta.valid && meta.dirty && checked)}">
                    確認訂位
                  </button> -->
                    <button class="btn btn-main md:px-5 md:py-2" type="submit" :class="{disabled : !(meta.valid && checked)}">
                      確認訂位
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <CardConfirm :storeID="store.store.id" :store="store" :loading="loading" class="hidden lg:block"> </CardConfirm>
        </div>
      </main>
    </div>
    <Footer />
  </div>
</template>

<script>
import CardConfirm from "@/components/Card_confirm.vue";
import CarouselBanner from "@/components/CarouselBanner.vue";
import CardStoreInfo from "@/components/CardStoreInfo.vue";
import Footer from "@/components/Footer.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Field, Form } from "vee-validate";
import { useForm } from "vee-validate";
import * as Yup from "yup";

const lengths = 5;
const starOn = "on";
const starHalf = "half";
const starOff = "off";

export default {
  name: "Info",
  data() {
    return {
      isLoading: false,
      id: "",
      storeCode: "",
      storeID: "",
      orderId: "", // 訂單編號
      editDetail: false,
      checked: "",
      userOrder: {
        store_id: "",
        name: "",
        gender: 0,
        phone: "",
        phone_prefix: "886",
        email: "",
        adult_num: "",
        child_num: "",
        reservation_date: "",
        reservation_time: "",
        goal_id: 1,
        child_seat_num: "",
        comment: "",
        from_source: 0,
      },
    };
  },
  components: {
    CarouselBanner,
    CardConfirm,
    CardStoreInfo,
    Footer,
    Field,
    Form,
    Loading,
  },
  setup() {
    // const { isSubmitting } = useForm();
    const schema = Yup.object().shape({
      title: Yup.string().required("請選擇稱謂"),
      name: Yup.string().required("請填入姓名"),
      phone: Yup.string()
        .matches(/^[0-9\s- +]*$/g, "格式不正確 請重新輸入")
        .matches(/^09[0-9]{8}$/, "請填入正確的手機號碼")
        .min(10, "請輸入10位數手機號碼")
        .max(10, "請輸入10位數手機號碼")
        .required("請填入手機號碼"),
      email: Yup.string().required("請填入電子信箱").email("信箱格式不正確"),
      // acceptTerms: Yup.string().required("請同意並閱讀隱私條款"),
    });
    return {
      schema,
      // isSubmitting,
    };
  },
  methods: {
    onSubmit() {
      const api = `${process.env.VUE_APP_API}/order`;
      this.userOrder.gender = this.userOrder.gender - 0;
      // this.userOrder.store_id = this.userOrder.store_id - 0;
      this.userOrder.store_id = this.$store.state.store.store.id;
      this.userOrder.adult_num = this.adultNum;
      this.userOrder.child_num = this.childNum;
      this.userOrder.child_seat_num = this.childseat;
      let apiDate;

      let d = new Date(this.$store.state.tempValue),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      apiDate = [year, month, day].join("-");

      this.userOrder.reservation_date = apiDate;
      this.userOrder.reservation_time = this.orderTime;

      const order = JSON.stringify(this.userOrder);
      this.isLoading = true;
      this.$http
        .post(api, order, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // this.$router.push(`/status/${response.data.return.id}`);
          location.replace(
            `${process.env.VUE_APP_WWW_URL}/booking-result/${response.data.return.id}`
          );

          this.isLoading = false;
          localStorage.clear();
        })
        .catch((error) => {
          if (error.response.data) {
            this.isLoading = false;
            alert(error.response.data.msg);
          }
        });
    },
    getCookie(Name) {
      //cookie
      var search = Name + "=";
      var returnValue = "";

      if (document.cookie.length > 0) {
        var offset = document.cookie.indexOf(search);
        if (offset !== -1) {
          offset += search.length;
          var end = document.cookie.indexOf(";", offset);
          if (end == -1) {
            end = document.cookie.length;
          }
          returnValue = decodeURIComponent(
            document.cookie.substring(offset, end)
          );
        }
      }
      return returnValue;
    },
    delCookie(name) {
      var exp = new Date();
      exp.setTime(exp.getTime() - 1);
      if (document.cookie.length > 0) {
        document.cookie = "popped" + "=;expires=" + new Date(0).toGMTString();
      }
    },
    getData() {
      this.$store.dispatch("getData");
    },
    changeEditDetail() {
      this.editDetail = false;
    },
    edit() {
      this.editDetail = true;
      localStorage.setItem("adultNum", this.userOrder.adult_num);
      localStorage.setItem("childNum", this.userOrder.child_num);
      localStorage.setItem("childseat", this.userOrder.child_seat_num);
      localStorage.setItem("tempValue", this.tempValue);
      localStorage.setItem("orderTime", this.userOrder.reservation_time);

      let year = JSON.parse(localStorage.getItem("clickYear"));
      let month = JSON.parse(localStorage.getItem("clickMonth"));
      let day = JSON.parse(localStorage.getItem("clickDay"));
      let days = new Date(year, month - 1, day);
      this.$store.commit("TEMPVALUE", days);
    },
    getInfo() {
      this.userOrder.adult_num = JSON.parse(localStorage.getItem("adultNum"));
      this.userOrder.child_num = JSON.parse(localStorage.getItem("childNum"));
      this.userOrder.child_seat_num = JSON.parse(
        localStorage.getItem("childseat")
      );
      this.userOrder.reservation_time = localStorage.getItem("orderTime");

      this.$store.commit(
        "CLICKDAY",
        JSON.parse(localStorage.getItem("clickDay"))
      );
      this.$store.commit(
        "CLICKMONTH",
        JSON.parse(localStorage.getItem("clickMonth"))
      );
      this.$store.commit(
        "CLICKYEAR",
        JSON.parse(localStorage.getItem("clickYear"))
      );
      this.$store.commit(
        "CLICKWEEK",
        JSON.parse(localStorage.getItem("clickWeek"))
      );

      this.userOrder.reservation_date = `${this.clickYear}-${this.clickMonth}-${this.clickDay}`;
    },
    clearAll() {
      this.userOrder.comment = "";
      this.userOrder.email = "";
      this.userOrder.name = "";
      this.userOrder.phone = "";
      document.getElementById("acceptTerms").checked = false;
    },
    getMember() {
      this.$store.dispatch("getMember");
    },
    getTwddMember() {
      if (this.$store.state.TWDDMember) {
        this.userOrder.name = this.$store.state.TWDDMember.name;
        this.userOrder.phone = this.$store.state.TWDDMember.phone;
        this.userOrder.from_source = 1;
        if (
          this.$store.state.TWDDMember.gender === null ||
          this.$store.state.TWDDMember.gender === 2
        ) {
          this.userOrder.gender = 0;
        } else if (this.$store.state.TWDDMember.gender === 1) {
          this.userOrder.gender = this.$store.state.TWDDMember.gender;
        }
      }

      if (this.$store.state.TWDDFromSource === 1) {
        this.userOrder.from_source = 1;
      }
    },
  },
  computed: {
    adultNum() {
      return this.$store.state.adultNum;
    },
    childNum() {
      return this.$store.state.childNum;
    },
    childseat() {
      return this.$store.state.childseat;
    },
    clickYear() {
      return this.$store.state.clickYear;
    },
    clickMonth() {
      return this.$store.state.clickMonth;
    },
    clickDay() {
      return this.$store.state.clickDay;
    },
    clickWeek() {
      return this.$store.state.clickWeek;
    },
    orderTime() {
      return this.$store.state.orderTime;
    },
    average_star() {
      let result = [];
      let score = Math.floor(this.store.comments.avg_score * 2) / 2;
      let starhalf = score % 1 != 0 ? true : false;
      let fullstar = Math.floor(score);
      for (let i = 0; i < fullstar; i++) {
        result.push(starOn);
      }
      if (starhalf) {
        result.push(starHalf);
      }
      if (result.length < lengths) {
        let offstar = lengths - result.length;
        for (let i = 0; i < offstar; i++) {
          result.push(starOff);
        }
      }
      return result;
    },
    store() {
      return this.$store.state.store;
    },
    loading() {
      return this.$store.state.loading;
    },
  },

  mounted() {
    // window.scrollTo(0, 0);
  },
  created() {
    this.storeCode = this.$route.params.id;
    // this.userOrder.store_id = this.$route.params.id;
    this.$store.commit("STORECODE", this.$route.params.id);
    this.getData();
    this.getInfo();
    this.delCookie();
    this.getMember();
    this.getTwddMember();
    if (
      this.userOrder.adult_num === "" ||
      this.userOrder.reservation_date === null ||
      this.userOrder.reservation_time === null
      // this.$store.state.tempValue === ""
    ) {
      this.$router.push(`/store/${this.$route.params.id}`);
    }
  },
};
</script>

<style lang="scss">
.inputStyle {
  box-shadow: 0px 2px 4px rgba(5, 16, 55, 0.06), inset 0px 0px 0px 1px #cdd0dc;
  border-radius: 6px;
  padding: 8px 11px;
  background: url(../assets/image/input-arrow.svg) 90% 50% no-repeat scroll
    transparent;
  width: 90px;
  height: 36px;
  background-color: #fff;
}

@media (min-width: 1024px) {
  .inputStyle {
    height: 42px;
  }
}

@media (min-width: 1200px) {
  .inputStyle {
    width: 124px;
    height: 48px;
  }
}

select.inputStyle {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.banner {
  height: 70vh;
  padding: 0px;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 768px) {
  .banner {
    height: 20vh;
  }
}

.carousel__next {
  position: absolute;
  right: 20px;
  font-size: 1rem;
  color: white;
  outline: none;
  background-color: rgba(217, 221, 234, 0.3);
  outline: none;
  border-radius: 0px;
}

.carousel__next :focus {
  outline: none;
}

.carousel__prev {
  position: absolute;
  left: 20px;
  font-size: 1rem;
  color: white;
  background-color: rgba(217, 221, 234, 0.3);
  outline: none;
  border-radius: 0px;
}

.carousel__viewport,
.carousel__track,
.carousel__slide,
.carousel__slide img {
  height: 100%;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.purpose {
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  gap: 10px;
  width: 100%;
}
@media (min-width: 768px) {
  .purpose {
    width: 474px;
  }
}
@media (min-width: 1024px) {
  .purpose {
    width: 439px;
  }
}

.purposeBtn {
  height: 100%;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .purposeBtn {
    /* width: 148px; */
    height: 100%;
    border-radius: 6px;
  }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.info-event-tag {
  color: #fa6c3e;
  border: solid 1px #fa6c3e;
  border-radius: 5px;
  padding: 2px 10px;
  font-size: 12px;
  margin: 0.3rem 0;
  display: inline-block;
  img {
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .info-event-tag {
    padding: 6px 10px;
    font-size: 15px;
    margin: 21px 0 0 0;
  }
}

@media (min-width: 1024px) {
  .info-event-tag {
    margin: 21px 0 0 0;
  }
}
</style>
